import React from 'react';
import Return from "../return";
import {useSelector} from "react-redux";
import {columnsCardTypesConfigSelector} from "../../../../ducks/representations";
import {ORDERS_GRID} from "../../../../constants/grids";

const Returns = ({ form, onChange, settings, error }) => {
    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, ORDERS_GRID));

    return (
        <Return
            form={form}
            error={error}
            onChange={onChange}
            columnsConfig={columnsConfig}
            settings={settings}
        />
    );
};

export default Returns;
