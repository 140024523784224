import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Modal} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeReportSelectedColumnsRequest,
    configurationColumnsSelector,
    getReportConfigurationColumnsRequest,
    saveReportSelectedColumnsRequest,
    selectedColumnsSelector,
} from "../../ducks/reports"
import DragAndDrop from '../../components/DragAndDrop';
import {toast} from "react-toastify";

const ReportTemplateSettings = ({ open, typeApi, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [search, setSearch] = useState('');

    const selectedColumns = useSelector(selectedColumnsSelector) || [];
    const columns = useSelector(state => configurationColumnsSelector(state, selectedColumns)) || [];

    useEffect(() => {
        open && dispatch(getReportConfigurationColumnsRequest({typeApi}))
    }, [open]);

    const handleChange = selected => {
        dispatch(changeReportSelectedColumnsRequest(selected))
    };

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        if (columns && columns.find(i => i.isRequired)) {
            const column = columns.filter(i => i.isRequired);
            toast.error(t('Fields are required to create a carriage', {fields: column.map(i => t(i.displayName)).join(', ')}));
        } else {
            dispatch(saveReportSelectedColumnsRequest({
                typeApi,
                callbackSuccess: handleClose
            }))
        }
    };

    return (
        <Modal open={open} id="fieldModal">
            <Modal.Header>{t('template_settings')}</Modal.Header>
            <Modal.Content scrolling>
                <Form style={{ marginBottom: '16px' }}>
                    <Input
                        icon="search"
                        iconPosition="left"
                        placeholder={t('search_field')}
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Form>
                <div className="flex-container-justify">
                {(columns && columns.length > 0) ||
                (selectedColumns && selectedColumns.length) ? (
                    <DragAndDrop
                        key={'dnd' + typeApi}
                        type={typeApi}
                        left={columns}
                        right={selectedColumns}
                        isSettings
                        t={t}
                        search={search}
                        onChange={handleChange}
                        keySort={'displayNameKey'}
                    />
                ) : null}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('CancelButton')}</Button>
                <Button primary onClick={handleSave}>{t('SaveButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ReportTemplateSettings;
