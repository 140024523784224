import React, {useState} from 'react';
import {Button, Form, Grid, Dropdown} from "semantic-ui-react";
import Date from "../../components/BaseComponents/Date";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    exportProgressSelector, 
    shipmentRegistryExportToExcelRequest,
    reportExportServerPlanRequest,
    reportExportServerActualRequest,
    reportExportServerActualDriverRequest,
} from "../../ducks/reports";
import { SHIPMENT_REGISTRY_REPORT_TYPE} from "../../constants/reportType";
import {dateToString} from "../../utils/dateTimeFormater";
import ReportTemplateSettings from './reportTemplateSettings';

const ShipmentRegistryReport = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [params, setParams] = useState({
        startDate: dateToString(),
        endDate: dateToString(),
    });

    let [openTemplateSettings, setOpenTemplateSettings] = useState({ open: false });

    const exportProgress = useSelector(exportProgressSelector);

    const handleChangeParams = (e, { name, value }) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        setParams(newParams);
    };

    const generateReport = () => {
        dispatch(shipmentRegistryExportToExcelRequest({
            type: SHIPMENT_REGISTRY_REPORT_TYPE,
            params,
        }));
    };

    const exportServerPlan = () => {
        dispatch(reportExportServerPlanRequest({
            type: SHIPMENT_REGISTRY_REPORT_TYPE,
            params,
        }));
    };

    const exportServerActual = () => {
        dispatch(reportExportServerActualRequest({
            type: SHIPMENT_REGISTRY_REPORT_TYPE,
            params,
        }));
    };

    const exportServerActualDriver = () => {
        dispatch(reportExportServerActualDriverRequest({
            type: SHIPMENT_REGISTRY_REPORT_TYPE,
            params,
        }));
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_params">
                    <Form>
                        <Grid>
                            <Grid.Row columns={4} verticalAlign="bottom">
                                <Grid.Column>
                                    <Date
                                        name="startDate"
                                        value={params.startDate}
                                        maxDate={params.endDate}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name="endDate"
                                        value={params.endDate}
                                        minDate={params.startDate}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Button floated="right" primary onClick={() => setOpenTemplateSettings({ open: true })}>
                                        {t("template_settings")}           
                                    </Button>
                                </Grid.Column>
                                <Grid.Column>
                                    <Dropdown
                                        button
                                        text={t("upload")}
                                        loading={exportProgress} 
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={generateReport}
                                            >
                                                {t('exportToExcel')}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={exportServerPlan}
                                            >
                                                {t('exportServerPlan')}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={exportServerActual}
                                            >
                                                {t('exportServerActual')}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={exportServerActualDriver}
                                            >
                                                {t('exportServerActualDriver')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </div>
            <ReportTemplateSettings open={openTemplateSettings.open} typeApi={SHIPMENT_REGISTRY_REPORT_TYPE} onClose={() => setOpenTemplateSettings({ open: false })} />
        </div>
    )
};

export default ShipmentRegistryReport;
