import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeSelectedColumnsRequest,
    columnsSelector,
    getConfigurationColunmsRequest, saveSelectedColumnsRequest,
    selectedColumnsSelector,
    importProcessTypeSelector
} from "../../ducks/loadingData";
import DragAndDrop from "../DragAndDrop";
import {toast} from "react-toastify";
import FormField from "../../components/BaseComponents";
import { SELECT_TYPE } from '../../constants/columnTypes';

const TemplateSettings = ({ open, typeApi, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [processType, setProcessType] = useState(null);

    const selectedColumns = useSelector(selectedColumnsSelector) || [];
    const selectedImportProcessType = useSelector(importProcessTypeSelector) || null;
    const columns = useSelector(state => columnsSelector(state, selectedColumns)) || [];

    useEffect(() => {
        open && dispatch(getConfigurationColunmsRequest({typeApi}))
    }, [open]);

    useEffect(() => {
        setProcessType(selectedImportProcessType);
    }, [selectedImportProcessType])

    const handleChange = selected => {
        dispatch(changeSelectedColumnsRequest({importProcessType: processType ? processType : null ,columns: selected}))
    };

    const handleClose = () => {
        onClose();
        setProcessType(null);
    };

    const handleSave = () => {
        if (columns && columns.find(i => i.isRequired)) {
            console.log(columns);
            const column = columns.filter(i => i.isRequired);
            toast.error(t('Fields are required to create a carriage', {fields: column.map(i => t(i.displayName)).join(', ')}));
        } else {
            dispatch(saveSelectedColumnsRequest({
                typeApi,
                callbackSuccess: handleClose,
                t: t,
            }))
        }
    };

    const onChangeImportProcessType = (e, {value}) => {
        dispatch(changeSelectedColumnsRequest({importProcessType: value ? value : null, columns: selectedColumns}));
        setProcessType(value);
    }

    return (
        <Modal open={open} id="fieldModal">
            <Modal.Header>{t('template_settings')}</Modal.Header>
            <Modal.Content scrolling>
                {typeApi === 'orders' &&
                    <Form className="template-settings-form">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField 
                                        name="importProcessType"
                                        text="importProcessType"
                                        type={SELECT_TYPE}
                                        source="importProcessType"
                                        value={processType}
                                        onChange={onChangeImportProcessType}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                }
                <div className="flex-container-justify">
                {(columns && columns.length > 0) ||
                (selectedColumns && selectedColumns.length) ? (
                    <DragAndDrop
                        key={'dnd' + typeApi}
                        type={typeApi}
                        left={columns}
                        right={selectedColumns}
                        isSettings
                        t={t}
                        search={""}
                        onChange={handleChange}
                    />
                ) : null}
                </div>
                <div>
                    {`* ${t('required field for creating transportation')}`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('CancelButton')}</Button>
                <Button primary onClick={handleSave}>{t('SaveButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default TemplateSettings;
