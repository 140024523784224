import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { cardSelector } from '../../../../ducks/gridCard';
import Number from '../../../../components/BaseComponents/Number';
import FormField from '../../../../components/BaseComponents';
import { SELECT_TYPE, NUMBER_TYPE } from '../../../../constants/columnTypes';


const EditField = ({ value, name, onChange, error = [] }) => {
    return (
        <>
            {name === 'unitOfMeasurement' ? (
                <Grid.Column>
                    <FormField
                        name={name}
                        value={value}
                        error={error && error.includes('unitOfMeasurement')}
                        source={'unitOfMeasurement'}
                        onChange={onChange}
                        key={'unitOfMeasurement'}
                        type={SELECT_TYPE}
                        noLabel={true}
                        isRequired={true}
                        compact={true}
                    />
                </Grid.Column>
            ) : (
                <Grid.Column>
                  <FormField
                    value={value}
                    isInteger={false}
                    name={name}
                    onChange={onChange}
                    noLabel={true}
                    error={error && error.includes(name)}
                    isRequired={true}
                    type={NUMBER_TYPE}
                    size={'mini'}
                />
                </Grid.Column>
            )}
        </>
    );
};

const Parameters = ({ form, onChange, gridName, load, settings: baseSettings }) => {
    const { t } = useTranslation();
    let [parameters, setParameters] = useState([...(form.parameters || [])]);
    let [indexEdit, setIndexEdit] = useState(null);
    let [error, setError] = useState([]);

    const card = useSelector(state => cardSelector(state));

    const columns = [
        { name: 'unitOfMeasurement' },
        { name: 'length' },
        { name: 'width' },
        { name: 'height' },
        { name: 'weightGross' },
        { name: 'weightNet' },
        { name: 'liner' },
    ];

    useEffect(
        () => {
            setParameters(form.parameters || []);
        },
        [form.parameters],
    );

    const editParameters = parameters => {

        onChange(null, {
            name: 'parameters',
            value: parameters,
        })
    };

    const handleDeleteItem = index => {
        const newParameters = parameters.filter((item, i) => i !== index);
        editParameters(newParameters);
    };

    const handleEditItem = index => {
        setIndexEdit(index);
    };

    const handleSaveItem = () => {
        editParameters(parameters);
        setIndexEdit(null);
        setError([]);
    };

    const handleChangeField = (e, { name, value }) => {
        const newColumns = [...parameters];

        newColumns[indexEdit] = {
            ...parameters[indexEdit],
            [name]: value,
        };

        setParameters(newColumns);
    };

    const handleCancelItem = index => {
        setParameters([parameters]);
        setParameters([...form.parameters]);
        setIndexEdit(null);
        setError(false);
    };

    const handleAddParameters = () => {
        setParameters([...parameters, {}]);
        setIndexEdit(parameters.length);
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column className="add-right-elements">
                        <Button disabled={indexEdit !== null} onClick={handleAddParameters}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="padding-14">
                    <Table 
                    compact={true} size={'small'}>
                    
                        <Table.Header>
                            <Table.Row>
                                {columns.map(column => (
                                    <Table.HeaderCell key={column.name}>
                                        {t(column.name)}
                                    </Table.HeaderCell>
                                ))}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {parameters ? parameters.map((row, index) => (
                                <Table.Row key={row.id}>
                                    {columns.map(column => (
                                        <>
                                            {index === indexEdit ?
                                                (<Table.Cell
                                                    key={`cell_${row.id}_${column.name}_${index}`}
                                                    className='parameters-table__column'
                                                >
                                                    <EditField
                                                        value={row[column.name]}
                                                        name={column.name}
                                                        error={error}
                                                        onChange={handleChangeField}
                                                    />
                                                </Table.Cell>
                                                )
                                                :
                                                (
                                                    <Table.Cell
                                                        key={`cell_${row.id}_${column.name}_${index}`}
                                                        className='parameters-table__column'
                                                    >
                                                        {(column.name === 'unitOfMeasurement' && row[column.name]) ? t(row[column.name].name) : t(row[column.name])}
                                                    </Table.Cell>
                                                )}
                                        </>
                                    ))}
                                    <Table.Cell
                                        textAlign="right"
                                        className="table-action-buttons"
                                    >
                                        {index === indexEdit ? (
                                            <>
                                                <Button
                                                    icon
                                                    onClick={() => handleCancelItem(index)}
                                                >
                                                    <Icon name="undo alternate" />
                                                </Button>
                                                <Button
                                                    icon
                                                    onClick={() => handleSaveItem(index)}
                                                >
                                                    <Icon name="check" />
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleEditItem(index)}
                                                >
                                                    <Icon name="pencil alternate" />
                                                </Button>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleDeleteItem(index)}
                                                >
                                                    <Icon name="trash alternate" />
                                                </Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                                : null}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Parameters;
