import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const ScenarioSettingCell = ({ value, t, onChange, loading, isDisabled, status, fieldName }) => {
    return (
        <Checkbox
            toggle
            checked={value}
            name={fieldName}
            disabled={isDisabled}
            status={status}
            onClick={(event, { name, checked }) => onChange(event, { value: checked, fieldName: name, status: status })}
            className="field"
        />
    );
};

export default React.memo(ScenarioSettingCell);
