import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/CardLayout';
import { Button, Form} from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';

import {
    settingsSelector,
    settingsProgressSelector,
    getSettingsRequest,
    saveSettingsRequest
} from '../../ducks/settings';

import {
    companiesSelector,
    getSettingsCompaniesRequest
} from '../../ducks/profile';

const SettingsCard = props => {

    const {
        match = {},
        history,
        location,
        load,
        isModal,
        openModal,
        onClose: onCloseModal
    } = props;

    const { params = {} } = match;
    const { companyId } = params;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });
    let [columns, setColumns] = useState([]);
    let [form, setForm] = useState([]);
    let [company, setCompany] = useState(null);

    const settings = useSelector(state => settingsSelector(state));
    const progress = useSelector(state => settingsProgressSelector(state));
    const companies = useSelector(state => companiesSelector(state));

    const title = useMemo(() => `${t(`companySettings`)}: ${company?.name}`, [company]);

    const getForm = (settings) => {
        const form = {};

        if(settings) {
            settings.forEach(i => form[i.section] = i.values.map(value => ({
                ...value,
                name: `${i.section}_${value.name}`,
                displayName: value.name,
                section: i.section
            })));
        }
        return form;
    }

    useEffect(() => {
        dispatch(getSettingsRequest(companyId));
        
        if(!companies) {
            dispatch(getSettingsCompaniesRequest());
        }
    }, [companyId]);

    useEffect(() => {
        if(companies) {
            setCompany(companies.find(i => i.value == companyId));
        }
    }, [companies, companyId])

    useEffect(() => {
        if (settings && settings.length) {
            setForm(getForm(settings));
        }
    }, [settings]);

    const handleSave = () => {

        var settingsNew = [];

        for(let section in form) {
            settingsNew.push({
                section: section,
                values: form[section].map(i => (
                    {
                        name: i.displayName,
                        value: i.value,
                        type: i.type
                    }))
            });
        }

        dispatch(saveSettingsRequest({companyId, settings: settingsNew}));

        setNotChangeForm(true);
    };

    const handleChange = useCallback(
        (event, { name, value, ...prev }) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }

            const [section, column] = name.split('_');

            let formNew = {
                ...form,
            };

            formNew[section].find(i => i.name == name).value = value;
            setForm(formNew);
        },
        [notChangeForm, form],
    );


    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {
        history.goBack();
    };

    const handleClose = () => {

        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_settings'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div>

            </div>
        );
    }, []);

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm || progress}
                    loading={progress}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [columns, notChangeForm, progress]);

    const getContent = useCallback(() =>
        settings.map(item => ({
            menuItem: t(item.section),
            render: () => (
                <div>
                    <Form className="role-form">
                        { 
                        form[item.section]?.map(column =>
                        (
                            <FormField
                                {...column}
                                key={column.name}
                                value={column.value}
                                onChange={handleChange}
                            />
                        ))}
                    </Form>
                </div>)
        })));

    return (
        <CardLayout
            title={title}
            actionsFooter={getActionsFooter}
            actionsHeader={getActionsHeader}
            onClose={handleClose}
            loading={progress}
            content={getContent}
        >
        </CardLayout>
    )
}

export default SettingsCard
