import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {columnsCardTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import {Divider, Form, Grid, Icon} from "semantic-ui-react";
import Return from "../return";
import {clearError} from "../../../../ducks/gridCard";

const Returns = ({form = {}, onChange, settings, error}) => {
    const {t} = useTranslation();

    const orderReturns = form.orderReturns && form.orderReturns.length ? form.orderReturns : [{}];

    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));

    const dispatch = useDispatch();

    const handleChange = (e, {name, value}, index) => {
        const newData = [...orderReturns];
        newData[index] = {
            ...newData[index],
            [name]: value
        };
        onChange(null, {name: 'orderReturns', value: newData});
        dispatch(clearError(`${name}_${index}`));

    }

    const handleDelete = (index) => {
        const newData = [...orderReturns];
        newData.splice(index, 1);
        onChange(null, {name: 'orderReturns', value: newData});
    }

    const handleCreate = () => {
        const newData = [...orderReturns, {}];
        onChange(null, {name: 'orderReturns', value: newData});
    }

    return (
        <>
            {orderReturns.map((orderReturn, index) => (<Form key={'return'+index} className="tabs-card return__form">
                <div className='return__delete-btn'>
                    <Icon name='delete' onClick={() => handleDelete(index)}/>
                </div>
                <Grid>
                    <Return
                        index={index}
                        isShipping
                        orders={form.orders}
                        form={orderReturn}
                        error={error}
                        onChange={(e, {name, value}) => handleChange(e, {name, value}, index)}
                        columnsConfig={columnsConfig}
                        settings={settings}
                        orderNumbers={orderReturns.filter(v => v.orderNumber).map((v => v.orderNumber))}
                    />
                </Grid>
            </Form>))}
            <div className='return__add-btn'>
                <Divider horizontal><Icon name='plus' onClick={handleCreate}/></Divider>
            </div>
        </>
    );
};

export default Returns;
