import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Form, Grid, Icon, Table} from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {SELECT_TYPE, ENUM_TYPE} from '../../../../constants/columnTypes';


const EditField = ({value, name, onChange, error = [], source, type}) => {
    return (
        <Grid.Column>
            <FormField
                name={name}
                value={value}
                error={error && error.includes(name)}
                source={source}
                onChange={onChange}
                key={name}
                type={type}
                noLabel={true}
                isRequired={true}
                compact={true}
            />
        </Grid.Column>
    );
};

const Vehicles = ({form, onChange}) => {
    const {t} = useTranslation();
    let [parameters, setParameters] = useState([...(form.vehicles || [])]);
    let [indexEdit, setIndexEdit] = useState(null);
    let [error, setError] = useState([]);

    const columns = [
        {
            name: 'vehicleId',
            source: 'vehicles',
            type: SELECT_TYPE

        },
        {
            name: 'vehicleOwnershipType',
            source: 'vehicleOwnershipType',
            type: ENUM_TYPE

        }
    ];

    useEffect(
        () => {
            setParameters(form.vehicles || []);
        },
        [form.vehicles],
    );

    const editParameters = vehicles => {
        onChange(null, {
            name: 'vehicles',
            value: vehicles,
        })
    };

    const handleDeleteItem = index => {
        const newParameters = parameters.filter((item, i) => i !== index);
        editParameters(newParameters);
    };

    const handleEditItem = index => {
        setIndexEdit(index);
    };

    const handleSaveItem = () => {
        editParameters(parameters);
        setIndexEdit(null);
        setError([]);
    };

    const handleChangeField = (e, {name, value}) => {
        const newColumns = [...parameters];

        newColumns[indexEdit] = {
            ...parameters[indexEdit],
            [name]: value,
        };

        setParameters(newColumns);
    };

    const handleCancelItem = index => {
        setParameters([parameters]);
        setParameters([...form.vehicles]);
        setIndexEdit(null);
        setError(false);
    };

    const handleAddParameters = () => {
        setParameters([...parameters, {}]);
        setIndexEdit(parameters.length);
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column className="add-right-elements">
                        <Button disabled={indexEdit !== null} onClick={handleAddParameters}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="padding-14">
                    <Table
                        compact={true} size={'small'}>

                        <Table.Header>
                            <Table.Row>
                                {columns.map(column => (
                                    <Table.HeaderCell key={column.name}>
                                        {t(column.name)}
                                    </Table.HeaderCell>
                                ))}
                                <Table.HeaderCell/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {parameters ? parameters.map((row, index) => (
                                    <Table.Row key={row.id}>
                                        {columns.map(column => (
                                            <>
                                                {index === indexEdit ?
                                                    (<Table.Cell
                                                            key={`cell_${row.id}_${column.name}_${index}`}
                                                            width={'7'}
                                                        >
                                                            <EditField
                                                                value={row[column.name]}
                                                                name={column.name}
                                                                error={error}
                                                                onChange={handleChangeField}
                                                                source={column.source}
                                                                type={column.type}
                                                            />
                                                        </Table.Cell>
                                                    )
                                                    :
                                                    (
                                                        <Table.Cell
                                                            key={`cell_${row.id}_${column.name}_${index}`}
                                                        >
                                                            {row[column.name] ? t(row[column.name].name) : ''}
                                                        </Table.Cell>
                                                    )}
                                            </>
                                        ))}
                                        <Table.Cell
                                            textAlign="right"
                                            className="table-action-buttons"
                                        >
                                            {index === indexEdit ? (
                                                <>
                                                    <Button
                                                        icon
                                                        onClick={() => handleCancelItem(index)}
                                                    >
                                                        <Icon name="undo alternate"/>
                                                    </Button>
                                                    <Button
                                                        disabled={!row[columns[0].name]}
                                                        icon
                                                        onClick={() => handleSaveItem(index)}
                                                    >
                                                        <Icon name="check"/>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        disabled={indexEdit !== null}
                                                        icon
                                                        onClick={() => handleEditItem(index)}
                                                    >
                                                        <Icon name="pencil alternate"/>
                                                    </Button>
                                                    <Button
                                                        disabled={indexEdit !== null}
                                                        icon
                                                        onClick={() => handleDeleteItem(index)}
                                                    >
                                                        <Icon name="trash alternate"/>
                                                    </Button>
                                                </>
                                            )}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                : null}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Vehicles;
