import React from 'react';
import TerminalBandwidthParameters from './shippingWarehouseTabs/terminalBandwidthParameters';
import CardLayout from '../../../components/CardLayout';
import Content from '../customDictionary/cardContent';
import ScheduleTab from '../customDictionary/warehouses/scheduleTab';

const ShippingWarehouseTabs = ({
    props,
    form,
    columns,
    name,
    id,
    error,
    title,
    onClose,
    onChangeForm,
    actionsFooter,
    actionsHeader,
    openModal,
    onOpenModal,
    loading

}) => {


    const getPanes = () => {
        let obj = [
            {
                menuItem: 'general info',
                render: () => (
                    <Content
                        columns={columns}
                        error={error}
                        form={form}
                        handleChange={onChangeForm}
                    />
                ),
            },
            {
                menuItem: 'terminalBandwidthParameters',
                render: () => (
                    <TerminalBandwidthParameters
                        form={form}
                        onChange={onChangeForm}
                        gridName={name}
                        load={loading}
                        error={error}
                    />
                )
            }
        ];

        if (columns && columns.find(item => item.name === "scheduleTitle")) {
            obj.push({
                menuItem: 'Warehouse.Schedule',
                render: () => (
                    <ScheduleTab columns={columns} error={error} form={form} onChange={onChangeForm} />
                ),
            })
        }
        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />

    );
};

export default ShippingWarehouseTabs;
