import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { cardSelector } from '../../../../ducks/gridCard';
import FormField from '../../../../components/BaseComponents';
import { MULTI_SELECT_TYPE, TEXT_TYPE } from '../../../../constants/columnTypes';


const EditField = ({ value, name, onChange, error = [] }) => {
    return (
        <>
            {name === 'unacceptableTonnageIds' ? (
                <Grid.Column>
                    <FormField
                        name={name}
                        value={value}
                        error={error && error.includes('unacceptableTonnageIds')}
                        source={'tonnages'}
                        onChange={onChange}
                        key={'unacceptableTonnageIds'}
                        type={MULTI_SELECT_TYPE}
                        noLabel={true}
                        isRequired={true}
                    />
                </Grid.Column>
            ) : (
                <Grid.Column>
                    <FormField
                        value={value}
                        name={name}
                        onChange={onChange}
                        noLabel={true}
                        error={error && error.includes(name)}
                        isRequired={true}
                        type={TEXT_TYPE}
                    />
                </Grid.Column>
            )}
        </>
    );
};

const TerminalBandwidthParameters = ({ form, onChange, gridName, load, settings: baseSettings }) => {
    const { t } = useTranslation();
    let [terminalBandwidthParameters, setTerminalBandwidthParameters] = useState([...(form.terminalBandwidthParameters || [])]);
    let [indexEdit, setIndexEdit] = useState(null);
    let [error, setError] = useState([]);

    const card = useSelector(state => cardSelector(state));

    const columns = [
        { name: 'numberTerminal' },
        { name: 'unacceptableTonnageIds' }
    ];

    useEffect(
        () => {
            setTerminalBandwidthParameters(form.terminalBandwidthParameters || []);
        },
        [form.terminalBandwidthParameters],
    );

    const editTerminalBandwidthParameters = terminalBandwidthParameters => {

        onChange(null, {
            name: 'terminalBandwidthParameters',
            value: terminalBandwidthParameters,
        })
    };

    const handleDeleteItem = index => {
        const newTerminalBandwidthParameters = terminalBandwidthParameters.filter((item, i) => i !== index);
        editTerminalBandwidthParameters(newTerminalBandwidthParameters);
    };

    const handleEditItem = index => {
        setIndexEdit(index);
    };

    const handleSaveItem = () => {
        editTerminalBandwidthParameters(terminalBandwidthParameters);
        setIndexEdit(null);
        setError([]);
    };

    const handleChangeField = (e, { name, value }) => {
        const newColumns = [...terminalBandwidthParameters];

        newColumns[indexEdit] = {
            ...terminalBandwidthParameters[indexEdit],
            [name]: value,
        };

        setTerminalBandwidthParameters(newColumns);
    };

    const handleCancelItem = index => {
        setTerminalBandwidthParameters([terminalBandwidthParameters]);
        setTerminalBandwidthParameters([...form.terminalBandwidthParameters]);
        setIndexEdit(null);
        setError(false);
    };

    const handleAddTerminalBandwidthParameters = () => {
        setTerminalBandwidthParameters([...terminalBandwidthParameters, {}]);
        setIndexEdit(terminalBandwidthParameters.length);
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column className="add-right-elements">
                        <Button disabled={indexEdit !== null} onClick={handleAddTerminalBandwidthParameters}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="padding-14">
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                {columns.map(column => (
                                    <Table.HeaderCell key={column.name}>
                                        {t(column.name)}
                                    </Table.HeaderCell>
                                ))}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {terminalBandwidthParameters ? terminalBandwidthParameters.map((row, index) => (
                                <Table.Row key={row.id}>
                                    {columns.map(column => (
                                        <>
                                            {index === indexEdit ?
                                                (<Table.Cell
                                                    key={`cell_${row.id}_${column.name}_${index}`}
                                                    width={'4'}
                                                >
                                                    <EditField
                                                        value={row[column.name]}
                                                        name={column.name}
                                                        error={error}
                                                        onChange={handleChangeField}
                                                    />
                                                </Table.Cell>
                                                )
                                                :
                                                (
                                                    <Table.Cell
                                                        key={`cell_${row.id}_${column.name}_${index}`}
                                                        width={'4'}
                                                    >
                                                        {(column.name === 'unacceptableTonnageIds' && row[column.name])
                                                            ? (row[column.name].map((item, index) => index != 0 ? ' , ' + item.name : item.name))
                                                            : row[column.name]}

                                                    </Table.Cell>
                                                )}
                                        </>
                                    ))}
                                    <Table.Cell
                                        textAlign="right"
                                        className="table-action-buttons"
                                    >
                                        {index === indexEdit ? (
                                            <>
                                                <Button
                                                    icon
                                                    onClick={() => handleCancelItem(index)}
                                                >
                                                    <Icon name="undo alternate" />
                                                </Button>
                                                <Button
                                                    icon
                                                    onClick={() => handleSaveItem(index)}
                                                >
                                                    <Icon name="check" />
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleEditItem(index)}
                                                >
                                                    <Icon name="pencil alternate" />
                                                </Button>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleDeleteItem(index)}
                                                >
                                                    <Icon name="trash alternate" />
                                                </Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                                : null}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default TerminalBandwidthParameters;
