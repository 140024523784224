import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {columnsCardTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import {Form, Grid} from "semantic-ui-react";
import FormField from "../formField";

const Driver = ({ form = {}, onChange, settings, error }) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="driverId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="vehicleId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="vehicleTypeId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                        <Grid.Column>
                            <FormField
                                name="powerOfAttorneyNumber"
                                text="powerOfAttorneyNumber"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="dateOfPowerOfAttorney"
                                text="dateOfPowerOfAttorney"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Driver;
