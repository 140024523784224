import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Number = ({
    value = '',
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    placeholder,
    isRequired,
    isInteger,
    autoFocus,
    isGrid,
    size,
    numberPoint
}) => {
    const { t } = useTranslation();

    value = value ?? '';

    const handleOnChange = (e, { value }) => {
        if (isInteger && value.length <= 9) {
            onChange(e, {
                name,
                value: value.toString().replace(/[^\d-]/g, ''),
            });
        }

        if (!isInteger && value.length <= 12) {
            onChange(e, {
                name,
                value: numberPoint ? value.toString().replace(/[^\d.]/g, '') : value.toString(),
            });
        }
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <Input
                placeholder={t(placeholder)}
                className={className}
                type={isInteger||numberPoint ? 'text' : 'number'}
                error={error}
                disabled={isDisabled || false}
                name={name}
                value={value}
                step="any"
                transparent={isGrid}
                autoFocus={autoFocus}
                onChange={handleOnChange}
                autoComplete="off"
                size={size}
            />
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default Number;
