import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import {
    shipmentRegisterRequest,
} from '../../ducks/loadingData';

const ExportCard = (props) => {

    const dispatch = useDispatch();

    const [params, setParams] = useState({});
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(true);

    const { history } = props;

    const columns = [
        {
            name: 'startDate',
            type: 'DateTime'
        },
        {
            name: 'endDate',
            type: 'DateTime'
        }
    ];

    const { t } = useTranslation();

    const onClose = () => {
        history.goBack();
    };
    const handleClose = () => {
        setErrors({});
        setParams({});
        onClose();
    }

    const handleConfirm = () => {
        dispatch(shipmentRegisterRequest(params));
    }

    const handleChange = (e, { name, value }) => {
        setParams(prev => {
            let form = {
                ...prev,
                [name]: value,
            };

            return form;
        });
    }

    let [formModal, setFormModal] = useState({ 
        open: true
        , columns: columns,
        onClose: onClose
    });

    return (
        <Modal open={open}>
            <Modal.Header>{t('fillForm')}</Modal.Header>
            <Modal.Content>
                <div className="ui form dictionary-edit" style={{ padding: 0 }}>
                    {columns.map(column => {
                        return (
                            <FormField
                                {...column}
                                key={column.name}
                                error={errors && errors[column.name]}
                                value={params[column.name]}
                                onChange={handleChange}
                            />
                        );
                    })}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('CancelButton')}</Button>
                <Button
                    primary
                    onClick={handleConfirm}
                >
                    {t('Ok')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ExportCard
