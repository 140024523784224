import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import FormField from "./formField";
import {Dropdown, Form} from "semantic-ui-react";
import {
    BIG_TEXT_TYPE,
    DATE_TIME_TYPE,
    MULTI_SELECT_TYPE,
    NUMBER_TYPE,
    SELECT_TYPE
} from "../../../constants/columnTypes";

const Return = ({form = {}, onChange, settings, error, columnsConfig, orders, index, orderNumbers}) => {
    const {t} = useTranslation();

    const options = useMemo(
        () => {
            return orders && orders.map(order => ({
                key: order.id,
                value: order.id,
                text: order.orderNumber,
            }));
        },
        [orders],
    );

    const returnFields = useMemo(
        () => {
            return [
                {
                    name: 'returnProductType',
                    source: 'returnType',
                    type: SELECT_TYPE
                },
                {
                    name: 'returnProductActualDate',
                    type: DATE_TIME_TYPE
                },
                {
                    name: 'returnProductMaterials',
                    type: MULTI_SELECT_TYPE,
                    source: 'orderArticles',
                    sourceParams: orders ? {orderId: form.orderNumber} : {id: form.id}
                },
                {
                    name: 'returnProductQuantity',
                    type: NUMBER_TYPE,
                    isInteger: true
                },
                {
                    name: 'returnProductSum',
                    type: NUMBER_TYPE
                },
                {
                    name: 'returnProductComment',
                    type: BIG_TEXT_TYPE
                }
            ];
        },
        [form.orderNumber, error],
    );

    return (
        <div className="ui form dictionary-edit">
            {
                orders && <Form.Field>
                    <label>{t('orderNumber')}</label>
                    <Dropdown
                        search
                        fluid
                        selection
                        options={options.filter(v => !orderNumbers.includes(v.value) || form['orderNumber'] === v.value)}
                        name="orderNumber"
                        value={form['orderNumber'] || null}
                        onChange={onChange}
                        clearable
                        error={error[`orderNumber_${index}`]}
                    />
                    {error[`orderNumber_${index}`] && <span className="label-error">{error[`orderNumber_${index}`]}</span>}
                </Form.Field>
            }
            {
                returnFields.map(column => (
                    <FormField
                        {...column}
                        key={column.name}
                        value={form}
                        columnsConfig={columnsConfig}
                        settings={settings}
                        form={form}
                        error={orders ? {[column.name]: error[`${column.name}_${index}`]} : error}
                        onChange={onChange}
                    />
                ))
            }
        </div>
    );
};

export default Return;
