import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import {
    DICTIONARY_SHIPPING_WAREHOUSES_LIST_LINK,
    DICTIONARY_SHIPPING_WAREHOUSES_CARD_LINK,
    DICTIONARY_SHIPPING_WAREHOUSES_NEW_LINK,

    DICTIONARY_ARTICLES_CARD_LINK,
    DICTIONARY_ARTICLES_NEW_LINK,
    DICTIONARY_ARTICLES_LIST_LINK,

    DICTIONARY_TARIFFS_CARD_LINK,
    DICTIONARY_TARIFFS_NEW_LINK,
    DICTIONARY_TARIFFS_LIST_LINK,

    DICTIONARY_CARD_LINK,
    DICTIONARY_LIST_LINK,
    DICTIONARY_NEW_LINK,
    FIELDS_SETTING_LINK, GRID_AUTO_GROUPING_LINK, DICTIONARY_SETTING_LINK,
    GRID_CARD_LINK,
    GRID_LIST_LINK,
    GRID_NEW_LINK,
    LOGIN_LINK,
    NEW_ROLE_LINK,
    NEW_USER_LINK, PROFILE_LINK, REPORT_LINK,
    ROLE_LINK,
    ROLES_LINK,
    USER_LINK,
    USERS_LINK,
    COMPANY_LINK,
    COMPANY_NEW_LINK,
    SETTINGS_LINK,
    SCENARIO_SETTING_LINK,
    SHIPMENT_REGISTER_LINK,
    CHANGE_PASSWORD_LINK
} from './links';
import CustomGrid from '../containers/customGrid/list';
import CustomGridCard from '../containers/customGrid/card';
import CustomDictionaryList from '../containers/dictionaries/customDictionary/list';
import CustomDictionaryCard from '../containers/dictionaries/customDictionary/card';
import ArticleDictionaryCard from '../containers/dictionaries/articles/articleCard';
import ArticleDictionaryList from '../containers/dictionaries/articles/articleList';
import ShippingWarehouseDictionaryCard from '../containers/dictionaries/shippingWarehouses/shippingWarehouseCard';
import ShippingWarehouseDictionaryList from '../containers/dictionaries/shippingWarehouses/shippingWarehouseList';
import TariffDictionaryCard from '../containers/dictionaries/tariffs/tariffCard';
import TariffDictionaryList from '../containers/dictionaries/tariffs/tariffList';
import PrivateRoute from './privateRoute';
import Login from '../containers/login';
import RolesList from '../containers/roles/roles_list';
import RoleCard from '../containers/roles/role_card_new';
import CompanyCard from '../containers/companies/company_card';
import UsersList from '../containers/users/users_list';
import UserCard from '../containers/users/user_card_new';
import FieldsSetting from '../containers/fieldsSetting/list';
import DictionarySetting from '../containers/dictionarySettings/list';
import { homePageSelector } from '../ducks/profile';
import Profile from "../containers/users/profile";
import Settings from "../containers/settings/settingsCard";
import AutoGrouping from "../containers/customGrid/autogrouping";
import OperationalReport from "../containers/reports/operationalReport";
import {OPERATIONAL_REPORT_TYPE, REGISTRY_REPORT_TYPE, SHIPMENT_REGISTRY_REPORT_TYPE} from "../constants/reportType";
import RegistryReport from "../containers/reports/registryReport";
import ShipmentRegistryReport from "../containers/reports/shipmentRegistryReport";
import ExportCard from "../containers/shipmentRegister/exportCard";
import ScenarioList from '../containers/fieldsSetting/scenarioSettings';
import ChangePasswordForm from "../containers/login/changePasswordForm";

const MainRoute = withRouter(props => {
    const homePage = useSelector(state => homePageSelector(state));

    useEffect(
        () => {
            const { history, location } = props;
            const { pathname } = location;
            if (pathname === '/grid' && homePage) {
                history.push(homePage);
            }
        },
        [homePage],
    );

    return (
        <Switch>
            <PrivateRoute exact path="/" component={() => <Redirect to={homePage} />} />
            <PrivateRoute exact path={CHANGE_PASSWORD_LINK} permission="profile" component={ChangePasswordForm} />
            <PrivateRoute exact path={GRID_NEW_LINK} component={CustomGridCard} />
            <PrivateRoute exact path={GRID_AUTO_GROUPING_LINK} permission="autogroupingOrders" component={AutoGrouping} />
            <PrivateRoute exact path={REPORT_LINK.replace(':type', OPERATIONAL_REPORT_TYPE)} permission={OPERATIONAL_REPORT_TYPE} component={OperationalReport} />
            <PrivateRoute exact path={REPORT_LINK.replace(':type', REGISTRY_REPORT_TYPE)} permission={REGISTRY_REPORT_TYPE} component={RegistryReport} />
            <PrivateRoute exact path={REPORT_LINK.replace(':type', SHIPMENT_REGISTRY_REPORT_TYPE)} permission={REGISTRY_REPORT_TYPE} component={ShipmentRegistryReport} />
            <PrivateRoute exact path={GRID_CARD_LINK} component={props => CustomGridCard(props)} />
            <PrivateRoute exact path={GRID_LIST_LINK} component={CustomGrid} />
            <PrivateRoute exact path={COMPANY_NEW_LINK} permission="editCompanies" component={CompanyCard} />
            <PrivateRoute exact path={COMPANY_LINK} permission="editCompanies" component={CompanyCard} />
            <PrivateRoute exact path={DICTIONARY_NEW_LINK} component={CustomDictionaryCard} />

            <PrivateRoute exact path={DICTIONARY_SHIPPING_WAREHOUSES_LIST_LINK} permission="editShippingWarehouses" component={ShippingWarehouseDictionaryList} />
            <PrivateRoute exact path={DICTIONARY_SHIPPING_WAREHOUSES_NEW_LINK} permission="editShippingWarehouses" component={ShippingWarehouseDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_SHIPPING_WAREHOUSES_CARD_LINK} permission="editShippingWarehouses" component={ShippingWarehouseDictionaryCard} />

            <PrivateRoute exact path={DICTIONARY_ARTICLES_LIST_LINK} permission="editArticles" component={ArticleDictionaryList} />
            <PrivateRoute exact path={DICTIONARY_ARTICLES_NEW_LINK} permission="editArticles" component={ArticleDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_ARTICLES_CARD_LINK} permission="editArticles" component={ArticleDictionaryCard} />

            <PrivateRoute exact path={DICTIONARY_TARIFFS_LIST_LINK} permission="editTariffs" component={TariffDictionaryList} />
            <PrivateRoute exact path={DICTIONARY_TARIFFS_NEW_LINK} permission="editTariffs" component={TariffDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_TARIFFS_CARD_LINK} permission="editTariffs" component={TariffDictionaryCard} />

            <PrivateRoute exact path={DICTIONARY_CARD_LINK} component={CustomDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_LIST_LINK} component={CustomDictionaryList} />
            <PrivateRoute exact path={NEW_ROLE_LINK} permission="editRoles" component={RoleCard} />
            <PrivateRoute exact path={ROLE_LINK} permission="editRoles" component={RoleCard} />
            <PrivateRoute exact path={ROLES_LINK} permission="editRoles" component={RolesList} />
            <PrivateRoute exact path={NEW_USER_LINK} permission="editUsers" component={UserCard} />
            <PrivateRoute exact path={USER_LINK} permission="editUsers" component={UserCard} />
            <PrivateRoute exact path={USERS_LINK} permission="editUsers" component={UsersList} />
            <PrivateRoute exact path={PROFILE_LINK} permission="profile" component={Profile} />
            <PrivateRoute exact path={SETTINGS_LINK} permission="profile" component={Settings} />
            <PrivateRoute exact path={SHIPMENT_REGISTER_LINK} permission="profile" component={ExportCard} />
            <PrivateRoute
                exact
                path={FIELDS_SETTING_LINK}
                permission="editFieldProperties"
                component={FieldsSetting}
            />
            <PrivateRoute
                exact
                path={DICTIONARY_SETTING_LINK}
                permission="editFieldProperties"
                component={DictionarySetting}
            />
            <PrivateRoute
                exact
                path={SCENARIO_SETTING_LINK}
                permission="editFieldProperties"
                component={ScenarioList}
            />
            <Route exact path={LOGIN_LINK} component={Login} />
            <PrivateRoute exact path="*" component={() => <Redirect to={homePage} />} />
        </Switch>
    );
});

export default MainRoute;
