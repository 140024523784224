import React, { useCallback, useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import FieldCell from './field_cell';
import SettingCell from './setting_cell';
import CellValue from '../../../components/ColumnsValue';
import { ACTIVE_TYPE } from '../../../constants/columnTypes';
import _, { uniq } from 'lodash';

const TableBody = ({
    statusList,
    changeSettings,
    isExt,
    editProgress,
    t,
    toggleFieldFlags,
    column
}) => {
    let [hidden, setHidden] = useState(column.isHidden);
    let [required, setRequired] = useState(column.isRequired);
    let [unique, setUnique] = useState(column.isUnique);

    useEffect(
        () => {
            setHidden(column.isHidden);
            setRequired(column.isRequired);
            setUnique(column.isUnique);
        },
        [column.isHidden, column.isRequired, column.isUnique],
    );

    useEffect(
        () => {
            if (hidden !== column.isHidden) {
                console.debug('hidden', hidden);
                toggleFieldFlags(column.fieldName, 'hidden', hidden, isExt);
            }
        },
        [hidden],
    );

    useEffect(
        () => {
            if (required !== column.isRequired) {
                toggleFieldFlags(column.fieldName, 'required', required, isExt);
            }
        },
        [required],
    );

    useEffect(
        () => {
            if (unique !== column.isUnique) {
                toggleFieldFlags(column.fieldName, 'unique', unique, isExt);
            }
        },
        [unique],
    );

    

    const handleOnChange = useCallback(
        (e, { value, fieldName, status }) => {
            changeSettings(fieldName, value, status, isExt);
        },
        [isExt],
    );

    return (
        <Table.Row key={column.fieldName}>
            <Table.Cell className="table-fields-setting_name field-settings-table_fixed">
                <FieldCell
                    field={column.fieldName}
                    fieldName={column.displayName}
                    isDisabled={column.isCalculated || hidden}
                    isExt={isExt}
                    t={t}
                    changeSettings={changeSettings}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={hidden}
                    type={ACTIVE_TYPE}
                    toggleIsActive={() => setHidden(!hidden)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={required}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isCalculated || hidden}
                    toggleIsActive={() => setRequired(!required)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={unique}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isCalculated || hidden}
                    toggleIsActive={() => setUnique(!unique)}
                />
            </Table.Cell>
            {statusList.map(status => (
                <Table.Cell key={`${status.name}_${column.fieldName}`}>
                    <SettingCell
                        value={column.accessTypes && column.accessTypes[status.name]}
                        isDisabled={column.isCalculated || hidden}
                        loading={
                            editProgress &&
                            (editProgress.field === column.fieldName &&
                                (!editProgress.state || editProgress.state === status.name))
                        }
                        status={status.name}
                        fieldName={column.fieldName}
                        onChange={handleOnChange}
                        t={t}
                    />
                </Table.Cell>
            ))}
        </Table.Row>
    );
};

export default React.memo(TableBody, (prevProps, currentProps) => {
    return _.isEqual(prevProps.column, currentProps.column)
});
