import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Table } from 'semantic-ui-react';
import { dictionariesSelector, roleIdSelector } from '../../ducks/profile';
import { getLookupRequest, companyListSelector } from '../../ducks/lookup';
import './style.scss';
import {
    clearFieldsSettings,
    editFieldsNameRequest,
    fieldsSettingSelector,
    getFieldsSettingRequest,
    fieldsSettingCompaniesSelector,
    getFieldsSettingCompaniesRequest,
    progressSelector,
    toggleHidenStateRequest,
} from '../../ducks/fieldsSetting';
import Header from './components/header';
import TableBody from './components/table_body';
import TableHeader from './components/table_header';
import { sortFunc } from '../../utils/sort';

const List = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const containerRef = useRef(null);

    let [role, setRole] = useState(null);
    let [company, setCompany] = useState(null);

    const dictionariesList = useSelector(state => dictionariesSelector(state, t)) || [];
    const rolesList = useSelector(state => companyListSelector(state, 'roles', company)) || [];
    const companiesList = useSelector(state => fieldsSettingCompaniesSelector(state)) || [];
    const settings = useSelector(state => fieldsSettingSelector(state)) || [];
    const loading = useSelector(state => progressSelector(state));
    const userRole = useSelector(state => roleIdSelector(state));

    let [dictionaryActiveItem, setDictionaryActiveItem] = useState(dictionariesList[0] || {});

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: 'roles',
                isForm: true,
            }),
        );

        dispatch(
            getFieldsSettingCompaniesRequest(),
        );
    }, [userRole]);

    useEffect(() => {
        dispatch(clearFieldsSettings());
        dictionaryActiveItem && role && company && getSettings();
    }, [role, dictionaryActiveItem, company]);

    useEffect(() => {
        if (rolesList.length) {
            setRole(rolesList[0].value);
        } 
    }, [company]);

    useEffect(() => {
        if (!role && rolesList.length) {
            setRole(userRole);
        }
    }, [rolesList]);

    useEffect(() => {
        if (!company && companiesList.length) {
            setCompany(companiesList[0].value);
        }
    }, [companiesList]);

    const getSettings = () => {
        dispatch(
            getFieldsSettingRequest({
                forEntity: dictionaryActiveItem,
                roleId: role,
                companyId: company,
            }),
        );
    };

    const handleChangeDictionaryActiveItem = useCallback((e, { value }) => {
        setDictionaryActiveItem(value);
    }, []);


    const handleChangSettings = useCallback(
        (fieldName, value) => {
            dispatch(
                editFieldsNameRequest({
                    params: {
                        forEntity: dictionaryActiveItem,
                        roleId: role === 'null' ? undefined : role,
                        companyId: company === 'null' ? undefined : company,
                        fieldName,
                        displayName: value
                    },
                    isExt: false,
                    callbackSuccess: () => {
                        getSettings();
                    },
                }),
            );
        },
        [dictionaryActiveItem, role, company],
    );

    const handleToggleSettingFlags = useCallback(
        (fieldName, settingName, value) => {
            dispatch(
                toggleHidenStateRequest({
                    params: {
                        forEntity: dictionaryActiveItem,
                        fieldName,
                        settingName,
                        roleId: role === 'null' ? undefined : role,
                        companyId: company === 'null' ? undefined : company,
                        value
                    },
                    isExt: false,
                    callbackSuccess: getSettings,
                }),
            );
        },
        [dictionaryActiveItem, role, company],
    );

    const handleChangeRole = useCallback((e, { value }) => {
        setRole(value);
    }, []);

    const handleChangeCompany = useCallback((e, { value }) => {
        setCompany(value);
    }, []);

    const { base: baseSettings = [], ext: extSettings = [] } = settings;

    return (
        <div className="container ui">
            <Loader active={loading && !baseSettings.length} size="huge" className="table-loader">
                Loading
            </Loader>
            <Header
                dictionariesList={dictionariesList}
                activeItem={dictionaryActiveItem}
                changeActiveItem={handleChangeDictionaryActiveItem}
                rolesList={rolesList}
                companiesList={companiesList}
                role={role}
                company={company}
                changeRole={handleChangeRole}
                changeCompany={handleChangeCompany}
                t={t}
            />
            
                <div className={`scroll-table-container field-settings-table`} ref={containerRef}>
                    <Table
                        className="ui celled selectable table grid-table table-info"
                        structured
                    >
                        <Table.Header>
                            <TableHeader t={t} />
                        </Table.Header>
                        <Table.Body className="table-fields-setting">
                            {baseSettings.length ? (
                                <>
                                    {sortFunc(baseSettings, t, 'fieldName').map(column => (
                                        <TableBody
                                            fieldName={column.fieldName}
                                            column={column}
                                            changeSettings={handleChangSettings}
                                            toggleFieldFlags={handleToggleSettingFlags}
                                            t={t}
                                        />
                                    ))}
                                </>
                            ) : null}

                        </Table.Body>
                    </Table>
                </div>
               
        </div>
    );
};

export default List;
