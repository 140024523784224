import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Label } from 'semantic-ui-react';

const FieldCell = ({ fieldName, fieldDescriptionKey, value, onChanged, onEdit }) => {
    const { t } = useTranslation();
    let [error, setError] = useState([]);
    let [isEdit, setIsEdit] = useState(false);
    let [currentValue, setCurrentValue] = useState(value);

    let prevValue = value;

    const startEdit = () => {

        if(onEdit) {
            onEdit(fieldName);
        }

        setIsEdit(true);
    };

    const acceptEdit = () => {

        if(currentValue != prevValue) {
            onChanged(fieldName, currentValue);
            prevValue = currentValue;
        }

        setIsEdit(false);
    };

    let description = fieldDescriptionKey && (<Label>{t(fieldDescriptionKey)}</Label>);

    return (
        <>
            {!isEdit ?
                (<>
                    <div className="cell-field-name">{currentValue}</div>
                    <Icon name="pencil" onClick={startEdit} />
                    {description}
                </>)
                : (<>
                    <Input
                        className="cell-field-name-edit"
                        value={currentValue}
                        name={fieldName}
                        onChange={(e, {value}) => setCurrentValue(value)}
                        error={error && error.includes(fieldName)}
                    />
                    <Icon name="check" onClick={acceptEdit} />
                    {description}
                </>)}
        </>
    );
};

export default React.memo(FieldCell);
