import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Form } from 'semantic-ui-react';

import './style.scss';
import {getLookupRequest, listFromMultiSelect, listFromSelectSelector, valuesListSelector} from '../../ducks/lookup';

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    label,
    name,
    text,
    multiple,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    children,
    sourceParams
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getLookup = () => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams,
            }),
        );
    }

    useEffect(() => {
        getLookup();
    }, [sourceParams]);

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, `${source}${(sourceParams && sourceParams.orderId) || ''}`, isTranslate, t)
    );

    const handleChange = (e, { value }) => {
        onChange(e, { value: value ? valuesList.filter(item => value.includes(item.value)) : null, name });
    };

    const handleSearch = (options, query) => {
        return options.filter(item => item && item.text.replaceAll(' ', '').includes(query.replaceAll(' ', '')));
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>{`${t(text || name)}${
                    isRequired ? ' *' : ''
                }`}</label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    placeholder={t(placeholder)}
                    fluid
                    deburr
                    clearable={clearable}
                    selection
                    loading={loading}
                    text={textValue}
                    error={error}
                    multiple
                    disabled={isDisabled}
                    value={value ? value.map(item => item.value) : null}
                    options={valuesList}
                    onChange={handleChange}
                    selectOnBlur={false}
                    autoComplete={autoComplete}
                    search={handleSearch}
                    onOpen={sourceParams ? getLookup : null}
                />
                {children && children}
            </div>
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(MultiSelect);
