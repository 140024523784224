import React from 'react';
import TariffLtls from './tariffTabs/tariffLtls';
import TariffAdditionalCosts from './tariffTabs/tariffAdditionalCosts';
import CardLayout from '../../../components/CardLayout';
import Content from '../customDictionary/cardContent';


const TariffTabs = ({
    props,
    form,
    columns,
    name,
    id,
    error,
    title,
    onClose,
    onChangeForm,
    actionsFooter,
    actionsHeader,
    openModal,
    onOpenModal,
    loading

}) => {

    const getPanes = () => {
        let obj = [
            {
                menuItem: 'general info',
                render: () => (
                    <Content
                        columns={columns}
                        error={error}
                        form={form}
                        handleChange={onChangeForm}
                    />
                ),
            },
            {
                menuItem: 'tariffLtls',
                render: () => (
                    <TariffLtls
                        form={form}
                        onChange={onChangeForm}
                        gridName={name}
                        load={loading}
                        error={error}
                    />
                )
            },
            {
                menuItem: 'tariffAdditionalCosts',
                render: () => (
                    <TariffAdditionalCosts
                        form={form}
                        onChange={onChangeForm}
                        gridName={name}
                        load={loading}
                        error={error}
                    />
                )
            }
        ];
        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />

    );
};

export default TariffTabs;
