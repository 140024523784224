import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { cardSelector } from '../../../../ducks/gridCard';
import Number from '../../../../components/BaseComponents/Number';
import FormField from '../../../../components/BaseComponents';
import { SELECT_TYPE } from '../../../../constants/columnTypes';


const EditField = ({ value, name, onChange, error = [] }) => {
    return (
        <>
            {
                <Number
                    value={value}
                    isInteger={name !== 'rate'}
                    name={name}
                    onChange={onChange}
                    noLabel={true}
                    error={error && error.includes(name)}
                    isRequired={true}
                />
            }
        </>
    );
};

const TariffLtls = ({ form, onChange, gridName, load, settings: baseSettings }) => {
    const { t } = useTranslation();
    let [tariffLtls, setTariffLtls] = useState([...(form.tariffLtls || [])]);
    let [indexEdit, setIndexEdit] = useState(null);
    let [error, setError] = useState([]);

    const card = useSelector(state => cardSelector(state));

    const columns = [{ name: 'countFrom' }, { name: 'countUpTo' }, { name: 'rate' }];
    useEffect(
        () => {
            setTariffLtls(form.tariffLtls || []);
        },
        [form.tariffLtls],
    );

    const editTariffLtls = tariffLtls => {

        onChange(null, {
            name: 'tariffLtls',
            value: tariffLtls,
        })
    };

    const handleDeleteItem = index => {
        const newTariffLtls = tariffLtls.filter((item, i) => i !== index);
        editTariffLtls(newTariffLtls);
    };

    const handleEditItem = index => {
        setIndexEdit(index);
    };

    const handleSaveItem = () => {
        editTariffLtls(tariffLtls);
        setIndexEdit(null);
        setError([]);
    };

    const handleChangeField = (e, { name, value }) => {
        const newColumns = [...tariffLtls];

        newColumns[indexEdit] = {
            ...tariffLtls[indexEdit],
            [name]: value,
        };

        setTariffLtls(newColumns);
    };

    const handleCancelItem = index => {
        setTariffLtls([tariffLtls]);
        setTariffLtls([...form.tariffLtls]);
        setIndexEdit(null);
        setError(false);
    };

    const handleAddTariffLtls = () => {
        setTariffLtls([...tariffLtls, {}]);
        setIndexEdit(tariffLtls.length);
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column className="add-right-elements">
                        <Button disabled={indexEdit !== null} onClick={handleAddTariffLtls}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="padding-14">
                    <Table className="wider container-margin-top-bottom">
                        <Table.Header>
                            <Table.Row>
                                {columns.map(column => (
                                    <Table.HeaderCell key={column.name}>
                                        {t(column.name)}
                                    </Table.HeaderCell>
                                ))}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tariffLtls ? tariffLtls.map((row, index) => (
                                <Table.Row key={row.id}>
                                    {columns.map(column => (
                                        <>
                                            {index === indexEdit ?
                                                (<Table.Cell
                                                    key={`cell_${row.id}_${column.name}_${index}`}
                                                    className={`table-edit-field-tariffs`}
                                                >
                                                    <EditField
                                                        value={row[column.name]}
                                                        name={column.name}
                                                        error={error}
                                                        onChange={handleChangeField}
                                                    />
                                                </Table.Cell>
                                                )
                                                :
                                                (
                                                    <Table.Cell
                                                        key={`cell_${row.id}_${column.name}_${index}`}
                                                    >
                                                        {row[column.name]}
                                                    </Table.Cell>
                                                )}
                                        </>
                                    ))}
                                    <Table.Cell
                                        textAlign="right"
                                        className="table-action-buttons"
                                    >
                                        {index === indexEdit ? (
                                            <>
                                                <Button
                                                    icon
                                                    onClick={() => handleCancelItem(index)}
                                                >
                                                    <Icon name="undo alternate" />
                                                </Button>
                                                <Button
                                                    icon
                                                    onClick={() => handleSaveItem(index)}
                                                >
                                                    <Icon name="check" />
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleEditItem(index)}
                                                >
                                                    <Icon name="pencil alternate" />
                                                </Button>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleDeleteItem(index)}
                                                >
                                                    <Icon name="trash alternate" />
                                                </Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                                : null}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default TariffLtls;
